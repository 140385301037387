<template>
    <div class="loginStyle">
        <header>
            <div class="logo"></div>
            <div class="shop">
                <P class="iconfont icon-bangzhu helpCenter">
                    帮助中心
                </P>
                <el-button type="primary" plain class="onTrialBtn">
                    免费试用
                </el-button>
                <p class="iconfont icon-dianhua contactInfo">
                    400  1234  567
                </p>
            </div>
        </header>
        <main>
            <component
                ref="ref_dynamicView"
                :is="dynamicView"
                @callPictureVerification="callPictureVerification"
                @resetPasswordLoadPage="resetPasswordLoadPage"
                @backLogin="backLogin"
            ></component>
        </main>
        <footer>
            <p>All Rights Reserved  使用本网站即表示接受 砼享云用户协议。版权所有 北京霁云科技有限公司 </p>
            <p>Copyright © 2022-现在concretecloud .com，All Rights Reserved  使用本网站即表示接受 砼享云用户协议。版权所有 北京霁云科技有限公司 </p>
        </footer>
        <el-dialog
            width="500px"
            title="请先完成安全验证"
            custom-class="pictureVerificationDialog"
            :visible.sync="showPictureVerification"
        >
            <pictureVerification v-if="showPictureVerification" @imageVerificationSucceeded="imageVerificationSucceeded"></pictureVerification>
        </el-dialog>
    </div>
</template>
<script>

import pictureVerification from './components/pictureVerification';

export default {
    // 选项卡嵌套表单页面
    name: 'login',
    components: {
        pictureVerification,
    },
    data() {
        return {
            dynamicViewIndex: null,
            dynamicView: null,
            // 图片验证弹出
            showPictureVerification: false,
            loginData: null,
        };
    },
    computed: {},
    created() {
        this.dynamicViewIndex = 1;
        this.dynamicView = () => import('./components/loginModule.vue');
    },
    mounted() {},
    methods: {
        // 短信登录-打开图片验证
        callPictureVerification(data) {
            this.loginData = data;
            this.showPictureVerification = true;
        },

        // 短信登录-图片验证成功
        imageVerificationSucceeded() {
            this.showPictureVerification = false;
            this.$message({
                showClose: true,
                message: '验证成功！',
                type: 'success',
            });
            if (this.dynamicViewIndex === 1) {
                this.$refs.ref_dynamicView.loginSucceeded(this.loginData);
            } else if (this.dynamicViewIndex === 2) {
                this.$refs.ref_dynamicView.pictureVerificationPassed(this.loginData);
            }
        },

        // 忘记密码
        resetPasswordLoadPage() {
            this.dynamicViewIndex = 2;
            this.dynamicView = () => import('./components/resetPasswordModule.vue');
        },

        // 返回登录
        backLogin() {
            this.dynamicViewIndex = 1;
            this.dynamicView = () => import('./components/loginModule.vue');
        },
    },
};
</script>
<style lang="stylus" scope>
@import './css/login.styl';
</style>
